.block__install {
  display: none;
}
@media (max-width: 768px) {
  .block__install {
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
    z-index: 999999;
    background: #fff;
    padding: 15px;
    box-shadow: 2px 3px 5px #ccc;
  }
  .block__install .inner {
    display: flex;
    align-items: center;
  }
  .block__install .inner .close {
    width: 32px;
    height: 32px;
    line-height: 32px;
  }
  .block__install .inner .close img {
    width: 32px;
    height: 32px;
  }
  .block__install .inner .logo {
    width: 48px;
  }
  .block__install .inner .logo img {
    width: 42px;
    border-radius: 2px;
  }
  .block__install .inner .name {
    padding-left: 10px;
  }
  .block__install .inner .name span {
    display: block;
  }
  .block__install .inner .name span.title {
    font-size: 1.125rem;
    line-height: 1;
    font-weight: 600;
  }
  .block__install .inner .cta {
    margin-left: auto;
  }
  .block__install.is-active {
    display: block;
  }
}
